import React from "react";

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      const newState = { ...state, isSidebarOpened: !state.isSidebarOpened };
      stateProvider.set(newState);
      return newState;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LayoutProvider({ children }) {
  var [state, dispatch] = React.useReducer(layoutReducer, stateProvider.get());
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState() {
  var context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar };

// ###########################################################
function toggleSidebar(dispatch) {
  dispatch({
    type: "TOGGLE_SIDEBAR",
  });
}

const stateProvider = {
  get: () => {
    const initialState = { isSidebarOpened: true };
    try {
      const savedState = JSON.parse(localStorage.getItem('layout'));
      return Object.assign(initialState, savedState || {});
    } catch (error) {
      console.warn(error);
    }
    return initialState;
  },
  set: state => {
    try {
      localStorage.setItem('layout', JSON.stringify(state));
    } catch (error) {
      console.warn(error);
    }
  }
}