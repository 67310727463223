import { FormControl, InputLabel, Select, MenuItem, ListItemText, Checkbox } from '@material-ui/core'
import React from 'react'

export default function Drivers(props) {
    const { getValue, setValue, drivers } = props;
    const items = drivers;
    const value = getValue() || [];

    const handleChange = (event) => {
        const selectedItems = event.target.value;
        setValue(selectedItems);
    };

    const renderValue = selected => {
        return selected.map(a => (items.find(b => b.id === a) || { text: 'Unknown' }).text).join(', ');
    };

    return (
        <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel>Drivers</InputLabel>
            <Select
                multiple
                value={value}
                label="Drivers"
                onChange={handleChange}
                renderValue={renderValue}
            >
                {items.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={!!value.find(a => a === item.id)} />
                        <ListItemText primary={item.text} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}