import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import CircularLoader from '../../components/Loaders/CircularLoader';
import Alert from '@material-ui/core/Alert'
import OrderRow from './order-row'
import OrderForm from './order-form'
import { useOrdersState, useOrdersDispatch, ACTIONS, loadOrders, filterOrders, onOrdersPageChange, fetchDrivers } from './context'
import { IconButton, FormControl, InputLabel, Select, MenuItem, Toolbar } from '@material-ui/core';

function OrdersToolbar(props) {
    const { state, dispatch } = props;
    function onStatusFilterChange(e) {
        filterOrders(state, dispatch, { ...state.filter, status: e.target.value })
    }
    return (
        <Toolbar sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 }
        }}>
            <IconButton title='New Order' onClick={() => dispatch({ type: ACTIONS.ADD_ORDER })}><AddIcon /></IconButton>
            <IconButton title='Refresh' onClick={() => loadOrders(state, dispatch, state.filter)}><RefreshIcon /></IconButton>
            <FormControl variant="standard" sx={{ float: "right", ml: "auto", mr: 1, minWidth: 150 }}>
                <InputLabel id="filter-by-status-label">Filter by status</InputLabel>
                <Select
                    labelId="filter-by-status-label"
                    id="filter-by-status-select"
                    value={state.filter.status}
                    onChange={onStatusFilterChange}
                    label="Filter by status"
                >
                    <MenuItem value="">
                        <em>All</em>
                    </MenuItem>
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="INACTIVE">Inactive</MenuItem>
                    <MenuItem value="FULFILLED">Fulfilled</MenuItem>
                    <MenuItem value="EXPIRED">Expired</MenuItem>
                </Select>
            </FormControl>
        </Toolbar>
    )
}

export default function OrderList(props) {
    const state = useOrdersState();
    const dispatch = useOrdersDispatch();

    useEffect(() => {
        fetchDrivers(state, dispatch);
        loadOrders(state, dispatch, state.filter);
    }, [])

    function handleChangePage(e, newPage) {
        onOrdersPageChange(state, dispatch, { page: newPage + 1, size: state.orders.size })
    }

    function handleChangeRowsPerPage(e) {
        onOrdersPageChange(state, dispatch, { page: 1, size: parseInt(e.target.value, 10) })
    }

    return (
        <div>
            <Snackbar open={!!state.alert}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                TransitionComponent={Slide}
                onClose={() => dispatch({ type: ACTIONS.CLOSE_ALERT })}>
                <Alert onClose={() => dispatch({ type: ACTIONS.CLOSE_ALERT })} severity={state.alert?.type} variant="filled" elevation={6} sx={{ width: '100%' }}>
                    {state.alert?.message}
                </Alert>
            </Snackbar>
            {state.orderForm.isOpen && <OrderForm />}
            <Paper sx={{ width: '100%', mb: 2 }}>
                <OrdersToolbar state={state} dispatch={dispatch} />
                <TableContainer>
                    {
                        state.isLoading ? <CircularLoader /> :
                            <>
                                <Table aria-label="collapsible table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell></TableCell>
                                            <TableCell>Origin</TableCell>
                                            <TableCell>Destination</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell align="right">Min Payout<br />Price</TableCell>
                                            <TableCell>Equipment</TableCell>
                                            <TableCell>Stops</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.orders.items.map((row) => (
                                            <OrderRow key={row.id} order={row} />
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={state.orders.total}
                                    rowsPerPage={state.orders.size}
                                    page={state.orders.page - 1}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>
                    }
                </TableContainer>
            </Paper>
        </div>
    );
}