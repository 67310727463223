import React from "react";
import Cookies from 'js-cookie';
import http from '../http';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!Cookies.get("Authorization"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, signOut, fetchUserProfile };

// ###########################################################

function signOut(dispatch, history) {
  Cookies.remove("Authorization")
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

async function fetchUserProfile(setProfile, setError) {
  return http.get('/user/profile')
    .then(res => res.data)
    .then(profile => setProfile(profile))
    .catch(err => {
      if (setError) setError(err);
      else console.warn(err);
    })
}