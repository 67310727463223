import React from 'react'
import { Box, Slider, InputLabel } from '@material-ui/core';

const min = 0, max = 28;

export function toMillis(hrs) {
    if (hrs)
        return hrs * 3_600_000
    return hrs
}

export function fromMillis(millis) {
    if (millis)
        return Math.floor(millis / 3_600_000)
    return millis
}

export const calculateValue = value => {
    if (value && value > 24) {
        return (value - 24) * 24 + 24
    }
    return value
}

export const calculatedValueBack = value => {
    if (value === null || value === undefined) return max;
    let val = fromMillis(value);
    if (val > 24) {
        return Math.floor(val / 24) - 1 + 24
    }
    return val
}

export function valueLabelFormat(value) {
    if (value === calculateValue(max)) return "Any";
    if (value === 0) return "0";
    if (value === 1) return "1 hr";
    if (value < 24) return value.toString() + ' hrs';
    const days = Math.floor(value / 24);
    if (days === 1) return "1 day";
    return days.toString() + ' days';
}

export function displayValue(value) {
    if (value[0] === value[1])
        return valueLabelFormat(calculateValue(value[0]))
    return valueLabelFormat(calculateValue(value[0])) + ' - ' + valueLabelFormat(calculateValue(value[1]))
}

export default function TripDuration(props) {

    const { getValue, setValue } = props;
    const value = getValue();
    //if (!value[1]) value[1] = max;
    value[0] = calculatedValueBack(value[0])
    value[1] = calculatedValueBack(value[1])

    const handleChange = (event, newValue) => {
        let [minVal, maxVal] = newValue;
        if (value[0] === minVal && value[1] === maxVal) return;
        if (maxVal === max) maxVal = null
        setValue([toMillis(calculateValue(minVal)), toMillis(calculateValue(maxVal))]);
    };

    return (
        <Box sx={{ mt: 2, ml: 1 }}>
            <InputLabel variant="standard">
                Trip duration: {displayValue(value)}
            </InputLabel>
            <Slider
                value={value}
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valueLabelFormat}
                onChange={handleChange}
                min={min}
                max={max}
                step={1}
                scale={calculateValue}
                valueLabelDisplay="auto"
            />
        </Box>
    )
}