import React from 'react'
import { Box, FormControlLabel, Switch } from '@material-ui/core';


export default function CustomSwitch(props) {
    const { getValue, setValue, label, readOnly = false } = props;
    const value = getValue() || false;

    const handleChange = (event) => {
        if (!readOnly)
            setValue(event.target.checked);
    };

    return (
        <Box sx={{ ml: 1 }}>
            <FormControlLabel control={<Switch readOnly={readOnly} checked={!!value} onChange={handleChange} />} label={label} />
        </Box>
    )
}