import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import { Divider, Radio } from "@material-ui/core";
import { useTheme } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularLoader from '../../components/Loaders/CircularLoader';
import Alert from '@material-ui/core/Alert'
import WorkType from './components/work-type';
import LoadType from './components/load-type';
import StopRange from './components/stop-range';
import EquipmentType from './components/equipment-type';
import { items as EquipmentTypes } from './components/equipment-type';
import Origin from './components/origin';
import OriginRadius from './components/origin-radius';
import ExcludedLocations from './components/excluded-locations';
import Destination from './components/destination';
import TotalDistance from './components/total-distance';
import TripDuration from './components/trip-duration';
import Payout from './components/payout';
import PricePerMile from './components/price-per-mile';
import TripType from './components/trip-type';
import TrailerStatus from './components/trailer-status';
import OrderType from './components/order-type';
import Drivers from './components/drivers';
import { useOrdersState, useOrdersDispatch, ACTIONS, createOrder, updateOrder } from './context'
import DateTime from './components/date-time';
import TimeWithin from './components/time-within';
import Timezone from './components/timezone';
import OrderStatus from './components/order-status';
import CustomSwitch from './components/custom-switch';

function initNewOrder() {
    return {
        status: 'ACTIVE',
        fulfillmentType: "BOOK",
        query: {},
        drivers: []
    }
}

function initQuery() {
    const [trailer53] = EquipmentTypes;
    return {
        workOpportunityTypeList: ['ONE_WAY', 'ROUND_TRIP'],
        originCity: null,
        startCityRadius: 50,
        excludedOrigins: [],
        multiselectDestinationCitiesRadiusFilters: [],
        exclusionCitiesFilter: [],
        maximumNumberOfStops: 4,
        minimumNumberOfStops: 1,
        minDistance: 0,
        maxDistance: null,
        minimumDurationInMillis: 0,
        maximumDurationInMillis: null,
        minPayout: 800,
        minPricePerDistance: 2,
        driverTypeFilters: ['SINGLE_DRIVER', 'TEAM_DRIVER'],
        loadingTypeFilters: ['LIVE', 'DROP'],
        trailerStatusFilters: ['PROVIDED'],
        equipmentTypeFilters: [trailer53.includes],
        equipmentTypeFiltersForTags: [trailer53.id],
        timezone: 'US/Central',
        startTime: null,
        startTimeLatest: null,
        endTime: null,
        startTimeWithinHoursMin: 0,
        startTimeWithinHours: 0,
        excludeExternalLoads: false,
        excludeRecoveryLoads: false,
        emptyTrailersOnly: false
    }
}

function OrderForm(props) {
    const theme = useTheme();

    const state = useOrdersState();
    const dispatch = useOrdersDispatch();
    const [order, setOrder] = useState(state.orderForm.order || initNewOrder())
    const [query, setQuery] = useState(state.orderForm.order?.query?.filter || initQuery())
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const filter = state.orderForm.order?.query?.filter;
    const [timeType, setTimeType] = useState(filter?.startTimeWithinHours ? "within" : "time"); // can be "time" or "within"

    function clear() {
        setLoading(false)
        setOrder(initNewOrder())
        setQuery(initQuery())
        setError(null)
    }

    function onCreate() {
        const data = {
            ...order,
            query: {
                filter: { ...query }
            }
        }
        createOrder(data, clear, setLoading, setError, state, dispatch);
    }

    function onSave() {
        const data = {
            ...order,
            query: {
                filter: { ...query }
            }
        }
        updateOrder(data, clear, setLoading, setError, state, dispatch);
    }

    const onTimeTypeChanged = (event) => {
        const type = event.target.value;
        setTimeType(type);
        if (type === "time") {
            query.startTimeWithinHoursMin = 0;
            query.startTimeWithinHours = 0;
        } else {
            query.startTime = null;
            query.startTimeLatest = null;
            query.startTimeWithinHours = 1;
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth='md'
            open={state.orderForm.isOpen}
            onClose={() => dispatch({ type: ACTIONS.CLOSE_ORDER_FORM })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {order.id ? 'Edit Order' : 'Add New Order'}
            </DialogTitle>
            <DialogContent>
                {loading && <CircularLoader />}
                {error && <Alert severity="error" sx={{ mb: 2, mr: 1 }}>{error.message}</Alert>}
                <Box component="form"
                    sx={{
                        '& .MuiFormControl-root': { m: 1 },
                    }}>
                    <Grid container columnSpacing={3}>
                        <Grid item sm={4} xs={12}>
                            <Origin
                                getValue={() => query.originCity}
                                setValue={value => setQuery({ ...query, originCity: value })} />
                        </Grid>
                        <Grid item sm={2} xs={12}>
                            <OriginRadius
                                getValue={() => query.startCityRadius}
                                setValue={value => setQuery({ ...query, startCityRadius: value })} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <ExcludedLocations
                                id="origin_exclusion"
                                getValue={() => query.excludedOrigins}
                                setValue={value => setQuery({ ...query, excludedOrigins: value })} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Destination
                                getValue={() => query.multiselectDestinationCitiesRadiusFilters}
                                setValue={value => setQuery({ ...query, multiselectDestinationCitiesRadiusFilters: value })} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <ExcludedLocations
                                id="dest_exclusion"
                                label="Excluded destinations"
                                getValue={() => query.exclusionCitiesFilter}
                                setValue={value => setQuery({ ...query, exclusionCitiesFilter: value })} />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Divider sx={{ marginTop: 4 }}>Times</Divider>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Timezone
                                label="Timezone"
                                getValue={() => query.timezone}
                                setValue={value => setQuery({ ...query, timezone: value })} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Radio value="time" checked={timeType === "time"} onChange={onTimeTypeChanged} />
                            <Paper variant="outlined"
                                sx={{ padding: 2, paddingRight: 4, borderColor: timeType === "time" ? "primary.light" : "secondary" }}>
                                <DateTime
                                    label="Start date"
                                    disabled={timeType !== "time"}
                                    timezone={query.timezone || 'US/Central'}
                                    getValue={() => query.startTime}
                                    setValue={value => setQuery({ ...query, startTime: value })} />
                                <DateTime
                                    label="Latest start date"
                                    disabled={timeType !== "time"}
                                    timezone={query.timezone || 'US/Central'}
                                    getValue={() => query.startTimeLatest}
                                    setValue={value => setQuery({ ...query, startTimeLatest: value })} />
                            </Paper>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Radio value="within" checked={timeType === "within"} onChange={onTimeTypeChanged} />
                            <Paper variant="outlined" sx={{ padding: 2, borderColor: timeType === "within" ? "primary.light" : "secondary" }}>
                                <TimeWithin
                                    disabled={timeType !== "within"}
                                    getValue={() => [query.startTimeWithinHoursMin, query.startTimeWithinHours]}
                                    setValue={([min, max]) => setQuery({ ...query, startTimeWithinHoursMin: min, startTimeWithinHours: max })} />
                            </Paper>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <DateTime
                                label="End date"
                                timezone={query.timezone || 'US/Central'}
                                getValue={() => query.endTime}
                                setValue={value => setQuery({ ...query, endTime: value })} />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Divider sx={{ marginTop: 2, marginBottom: 4 }} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <WorkType
                                getValue={() => query.workOpportunityTypeList}
                                setValue={value => setQuery({ ...query, workOpportunityTypeList: value })} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <StopRange
                                getValue={() => [query.minimumNumberOfStops, query.maximumNumberOfStops]}
                                setValue={([min, max]) => setQuery({ ...query, minimumNumberOfStops: min, maximumNumberOfStops: max })} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <EquipmentType
                                getValue={() => query.equipmentTypeFiltersForTags}
                                setValue={(value, children) => setQuery({ ...query, equipmentTypeFiltersForTags: value, equipmentTypeFilters: children })} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TotalDistance
                                getValue={() => [query.minDistance, query.maxDistance]}
                                setValue={([min, max]) => setQuery({ ...query, minDistance: min, maxDistance: max })} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TripDuration
                                getValue={() => [query.minimumDurationInMillis, query.maximumDurationInMillis]}
                                setValue={([min, max]) => setQuery({ ...query, minimumDurationInMillis: min, maximumDurationInMillis: max })} />
                        </Grid>
                        <Grid item sm={2} xs={6}>
                            <Payout
                                min={200}
                                max={15000}
                                getValue={() => query.minPayout}
                                setValue={value => setQuery({ ...query, minPayout: value })} />
                        </Grid>
                        <Grid item sm={2} xs={6}>
                            <PricePerMile
                                min={1}
                                max={10}
                                getValue={() => query.minPricePerDistance}
                                setValue={value => setQuery({ ...query, minPricePerDistance: value })} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TripType
                                getValue={() => query.driverTypeFilters}
                                setValue={value => setQuery({ ...query, driverTypeFilters: value })} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <LoadType
                                getValue={() => query.loadingTypeFilters}
                                setValue={value => setQuery({ ...query, loadingTypeFilters: value })} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TrailerStatus
                                getValue={() => query.trailerStatusFilters}
                                setValue={value => setQuery({ ...query, trailerStatusFilters: value })} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CustomSwitch
                                label="Exclude external loads"
                                getValue={() => query.excludeExternalLoads}
                                setValue={value => setQuery({ ...query, excludeExternalLoads: value })} />
                            <CustomSwitch
                                label="Exclude recovery(ITR) loads"
                                getValue={() => query.excludeRecoveryLoads}
                                setValue={value => setQuery({ ...query, excludeRecoveryLoads: value })} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CustomSwitch
                                label="Empty trailers only"
                                getValue={() => query.emptyTrailersOnly}
                                setValue={value => setQuery({ ...query, emptyTrailersOnly: value })} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Drivers
                                drivers={state.drivers}
                                getValue={() => order.drivers}
                                setValue={value => setOrder({ ...order, drivers: value })} />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <OrderStatus
                                getValue={() => order.status}
                                setValue={value => setOrder({ ...order, status: value })} />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <OrderType
                                getValue={() => order.fulfillmentType}
                                setValue={value => setOrder({ ...order, fulfillmentType: value })} />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch({ type: ACTIONS.CLOSE_ORDER_FORM })}>Close</Button>
                {order.id ? <Button onClick={onSave}>Save</Button> : <Button onClick={onCreate}>Create</Button>}
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(OrderForm)