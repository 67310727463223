import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { LocalizationProvider } from "@material-ui/lab";
import DateAdapter from '@material-ui/lab/AdapterDateFns';
import './index.css';
import App from './App';
// import Themes from "./themes";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <LocalizationProvider dateAdapter={DateAdapter}>
        {/* <ThemeProvider> */}
        <CssBaseline />
        <App />
        {/* </ThemeProvider> */}
      </LocalizationProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
