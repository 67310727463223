import React, { useState, useContext } from 'react';
import { MuiPickersAdapterContext } from '@material-ui/lab'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CheckCircle, CopyAll, Edit, HourglassBottomOutlined, Pause, PlayArrow, WatchLaterOutlined } from '@material-ui/icons';
import { ButtonGroup, Button, CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import { useOrdersDispatch, useOrdersState, changeOrderStatus, ACTIONS } from './context'
import * as TripDuration from './components/trip-duration'
import MatchedWOS from './matched-wos';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import CustomSwitch from './components/custom-switch';

function displayOrigin(query, withRadius = true) {
    let displayValue = query?.filter?.originCity?.displayValue;
    if (withRadius) {
        let startCityRadius = query?.filter?.startCityRadius;
        if (displayValue && startCityRadius) displayValue = `${displayValue} (${startCityRadius} mi)`
    }
    return displayValue || 'Anywhere';
}

function displayDestination(query) {
    const destinations = query?.filter?.multiselectDestinationCitiesRadiusFilters || [];
    if (destinations && destinations.length > 0) {
        return destinations.map(a => a?.displayValue).join(' / ');
    }
    return 'Anywhere';
}

function displayExcludedLocations(locations) {
    if (locations && locations.length > 0) {
        return locations.map(a => a?.displayValue).join(' / ');
    }
    return '';
}

function displayCurrency(val) {
    return val ? '$' + val : 'Any';
}

const equipmentTypes = {
    FIFTY_THREE_FOOT_TRUCK: "53' Trailer",
    TWENTY_SIX_FOOT_BOX_TRUCK: "26' Truck",
    TWO_PUP_TRAILERS: "28' Trailer",
    FIFTY_THREE_FOOT_REEFER_TRUCK: "53' Reefer",
    TWENTY_SIX_FOOT_REEFER_TRUCK: "26' Reefer",
    FIFTY_THREE_FOOT_CONTAINER: "53' Container",
    TWENTY_FOOT_CONTAINER: "20' Container",
    FORTY_FOOT_CONTAINER: "40' Container",
    FORTY_FIVE_FOOT_CONTAINER: "45' Container",
    FORTY_FOOT_HIGHCUBE_CONTAINER: "40' HC Container",
    FORTY_FIVE_FOOT_HIGHCUBE_CONTAINER: "45' HC Container"
}
function displayTruckInfo(query) {
    const equipmentTypeFilters = query?.filter?.equipmentTypeFiltersForTags || [];
    const trailerStatusFilters = query?.filter?.trailerStatusFilters || [];
    let equipmentTypesInfo = equipmentTypeFilters.map(a => equipmentTypes[a]).join(', ');
    let trailerStatus = trailerStatusFilters.join(', ');
    if (equipmentTypesInfo.length === 0) equipmentTypesInfo = 'Any';
    //if (trailerStatus.length > 0) trailerStatus = '(' + trailerStatus + ')'
    return `${equipmentTypesInfo}<br/>${trailerStatus}`;
}

function displayEquipmentType(query) {
    const equipmentTypeFilters = query?.filter?.equipmentTypeFiltersForTags || [];
    let equipmentTypesInfo = equipmentTypeFilters.map(a => equipmentTypes[a]).join(', ');
    if (equipmentTypesInfo.length === 0) equipmentTypesInfo = 'Any';
    return equipmentTypesInfo;
}

function displayStops(query) {
    const minimumNumberOfStops = query?.filter?.minimumNumberOfStops || 1;
    const maximumNumberOfStops = query?.filter?.maximumNumberOfStops || 'Any';
    return `${minimumNumberOfStops} - ${maximumNumberOfStops}`
}

function displayDateTime(date, dateAdapter, timezone) {
    if (!date) return 'Any';
    if (timezone) date = utcToZonedTime(date, timezone);
    return dateAdapter?.format(new Date(date), "keyboardDateTime12h");
}

function displayTimezone(timezone) {
    return timezone ? timezones[timezone] : timezones['US/Central']
}

const timezones = {
    "US/Eastern": "Eastern Time (EST)",
    "US/Central": "Central Time (CST)",
    "US/Mountain": "Mountain Time (MST)",
    "US/Pacific": "Pacific Time (PST)"
}

const woTypes = {
    BLOCK: 'Block',
    ONE_WAY: "One-way",
    ROUND_TRIP: "Round trip"
}
function displayWOType(query, isHtml = true) {
    const list = query?.filter?.workOpportunityTypeList || []
    return list.sort().map(a => woTypes[a]).join(isHtml ? '<br/>' : ', ')
}

function displayTotalDistance(query) {
    const min = query?.filter?.minDistance || 0;
    const max = query?.filter?.maxDistance || 'Any';
    return `${min} - ${max}`;
}

const tripTypes = {
    "SINGLE_DRIVER": "Solo",
    "TEAM_DRIVER": "Team"
}
function displayTripType(query, isHtml = true) {
    const list = query?.filter?.driverTypeFilters || []
    return list.sort().map(a => tripTypes[a]).join(isHtml ? '<br/>' : ', ')
}

const loadTypes = {
    "LIVE": "Live",
    "DROP": "Drop & Hook"
}
function displayLoadType(query, isHtml = true) {
    const list = query?.filter?.loadingTypeFilters || []
    return list.sort().map(a => loadTypes[a]).join(isHtml ? '<br/>' : ', ')
}

const trailerStatusTypes = {
    "PROVIDED": "Provided",
    "REQUIRED": "Required"
}
function displayTrailerStatus(query, isHtml = true) {
    const list = query?.filter?.trailerStatusFilters || []
    return list.sort().map(a => trailerStatusTypes[a]).join(isHtml ? '<br/>' : ', ')
}

function displayDrivers(order, allDrivers) {
    if (order.drivers && order.drivers.length > 0) {
        return order.drivers.map(driverId => {
            const item = allDrivers.find(a => a.id === driverId);
            return item ? item.text : 'Unknown';
        }).join(', ');
    }
    return "";
}

const orderTypes = {
    "NOTIFY": "Notify only",
    "BOOK": "Book & Notify"
}

function OrderDetails(props) {
    const dateAdapterContext = useContext(MuiPickersAdapterContext);
    const dateAdapter = dateAdapterContext.utils;
    const { order, allDrivers } = props;
    const { query } = order;
    const { filter } = query;

    return (
        <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item xs={4}>
                <TextField label="Origin" size="small" variant="standard" fullWidth
                    value={displayOrigin(order.query, false)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={2}>
                <TextField label="Radius (miles)" size="small" variant="standard" fullWidth
                    value={query?.filter?.startCityRadius || ''}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={6}>
                <TextField label="Excluded origins" size="small" variant="standard" fullWidth
                    value={displayExcludedLocations(filter?.excludedOrigins)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={6}>
                <TextField label="Destination" size="small" variant="standard" fullWidth
                    value={displayDestination(query)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={6}>
                <TextField label="Excluded destinations" size="small" variant="standard" fullWidth
                    value={displayExcludedLocations(filter?.exclusionCitiesFilter)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={3}>
                <TextField label="Timezone" size="small" variant="standard" fullWidth
                    value={displayTimezone(filter?.timezone)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={3}>
                <TextField label="Start date" size="small" variant="standard" fullWidth
                    value={displayDateTime(filter?.startTime, dateAdapter, filter?.timezone)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={3}>
                <TextField label="Start within (hrs)" size="small" variant="standard" fullWidth
                    value={filter?.startTimeWithinHours || 'Any'}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={3}>
                <TextField label="End date" size="small" variant="standard" fullWidth
                    value={displayDateTime(filter?.endTime, dateAdapter, filter?.timezone)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={4}>
                <TextField label="Work type" size="small" variant="standard" fullWidth
                    value={displayWOType(query, false)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={4}>
                <TextField label="Stops (min, max)" size="small" variant="standard" fullWidth
                    value={displayStops(query)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={4}>
                <TextField label="Equipment" size="small" variant="standard" fullWidth
                    value={displayEquipmentType(query)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={4}>
                <TextField label="Total distance (min, max)" size="small" variant="standard" fullWidth
                    value={displayTotalDistance(query)}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={4}>
                <TextField label="Trip (min, max)" size="small" variant="standard" fullWidth
                    value={TripDuration.displayValue([
                        TripDuration.calculatedValueBack(query?.filter?.minimumDurationInMillis),
                        TripDuration.calculatedValueBack(query?.filter?.maximumDurationInMillis)])}
                    InputProps={{
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={2}>
                <TextField label="Min Payout" size="small" variant="standard" fullWidth
                    value={filter?.minPayout || ''}
                    InputProps={{
                        readOnly: true,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }} />
            </Grid>
            <Grid item xs={2}>
                <TextField label="Min Price/Mile" size="small" variant="standard" fullWidth
                    value={filter?.minPricePerDistance || ''}
                    InputProps={{
                        readOnly: true,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }} />
            </Grid>
            <Grid item xs={4}>
                <TextField label="Trip type" size="small" variant="standard" fullWidth
                    value={displayTripType(query, false)}
                    InputProps={{
                        readOnly: true
                    }} />
            </Grid>
            <Grid item xs={4}>
                <TextField label="Load type" size="small" variant="standard" fullWidth
                    value={displayLoadType(query, false)}
                    InputProps={{
                        readOnly: true
                    }} />
            </Grid>
            <Grid item xs={4}>
                <TextField label="Trailer status" size="small" variant="standard" fullWidth
                    value={displayTrailerStatus(query, false)}
                    InputProps={{
                        readOnly: true
                    }} />
            </Grid>
            <Grid item sm={6} xs={12}>
                <CustomSwitch label="Exclude external loads"
                    getValue={() => query.filter.excludeExternalLoads}
                    readOnly={true} />
                <CustomSwitch label="Exclude recovery(ITR) loads"
                    getValue={() => query.filter.excludeRecoveryLoads}
                    readOnly={true} />
            </Grid>
            <Grid item sm={6} xs={12}>
                <CustomSwitch label="Empty trailers only"
                    getValue={() => query.filter.emptyTrailersOnly}
                    readOnly={true} />
            </Grid>
            <Grid item xs={9}>
                <TextField label="Drivers" size="small" variant="standard" fullWidth
                    value={displayDrivers(order, allDrivers)}
                    InputProps={{
                        readOnly: true
                    }} />
            </Grid>
            <Grid item xs={3}>
                <TextField label="Order type" size="small" variant="standard" fullWidth
                    value={orderTypes[order?.fulfillmentType]}
                    InputProps={{
                        readOnly: true
                    }} />
            </Grid>
        </Grid >
    )
}

function Status(props) {
    const { status } = props;
    let icon = <CircularProgress disableShrink size="1.5rem" />
    switch (status) {
        case 'INACTIVE':
            icon = <WatchLaterOutlined />
            break;
        case 'EXPIRED':
            icon = <HourglassBottomOutlined />
            break;
        case 'FULFILLED':
            icon = <CheckCircle color="success" />
            break;
        default: break;
    }
    return icon;
}

function getStatusBGColor(status) {
    switch (status) {
        case 'INACTIVE': return '#f3f3f3';
        case 'EXPIRED': return '#fffee9';
        case 'FULFILLED': return '#e1ffea';
        default: return '#fff'; // ACTIVE
    }
}

export default function OrderRow(props) {
    const { order } = props;
    const [open, setOpen] = useState(false);
    const state = useOrdersState();
    const dispatch = useOrdersDispatch();

    function changeStatus(newStatus) {
        changeOrderStatus(order, newStatus, state, dispatch);
    }

    function edit() {
        dispatch({ type: ACTIONS.UPDATE_ORDER, payload: order });
    }

    function copy() {
        dispatch({ type: ACTIONS.ADD_ORDER, payload: order });
    }

    function onRowClick(e) {
        if (!['button', 'a', 'svg', 'path'].includes(e.target.tagName.toLowerCase()))
            setOpen(!open)
    }

    return (
        <React.Fragment>
            <TableRow onClick={onRowClick} sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: getStatusBGColor(order.status) }}>
                <TableCell padding="checkbox">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell padding="none">
                    <Status status={order.status} />
                </TableCell>
                <TableCell>{displayOrigin(order.query)}</TableCell>
                <TableCell>{displayDestination(order.query)}</TableCell>
                <TableCell dangerouslySetInnerHTML={{ __html: displayWOType(order.query) }}></TableCell>
                <TableCell align="right">
                    {displayCurrency(order?.query?.filter?.minPayout)} | {displayCurrency(order?.query?.filter?.minPricePerDistance)}
                </TableCell>
                {/* <TableCell align="right">{displayCurrency(order?.query?.filter?.minPricePerDistance)}</TableCell> */}
                <TableCell dangerouslySetInnerHTML={{ __html: displayTruckInfo(order.query) }}></TableCell>
                <TableCell>{displayStops(order.query)}</TableCell>
                <TableCell>
                    <ButtonGroup sx={{ float: "right" }} disabled={order.isLoading}>
                        {
                            order.status === 'INACTIVE' &&
                            <Button variant="text" aria-label="Activate" onClick={() => changeStatus("ACTIVE")}>
                                <PlayArrow />
                            </Button>
                        }
                        {
                            order.status === 'ACTIVE' &&
                            <Button variant="text" aria-label="Inactivate" onClick={() => changeStatus("INACTIVE")}>
                                <Pause />
                            </Button>
                        }
                        {
                            ['ACTIVE', 'INACTIVE'].includes(order.status) &&
                            <Button variant="text" aria-label="Edit" onClick={edit}>
                                <Edit />
                            </Button>
                        }
                        <Button variant="text" aria-label="Copy and create new" onClick={copy}>
                            <CopyAll />
                        </Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: getStatusBGColor(order.status) }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2} />
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ mt: 2, mb: 3 }}>
                            <OrderDetails order={order} allDrivers={state.drivers} />
                            {order?.matches && <MatchedWOS matches={order?.matches} />}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}