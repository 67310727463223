import React from "react";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://highratez.com">
          HighRatez
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }