import { Autocomplete, TextField, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import _ from 'lodash'
import http from '../../../http';

function Destination(props) {
    const { getValue, setValue } = props;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timeoutHandle, setTimeoutHandle] = useState(null);

    const onInputChange = (event, val) => {
        if (timeoutHandle) clearTimeout(timeoutHandle);
        const handle = setTimeout(() => {
            if (!val || val.length < 2) {
                setOptions([])
                return;
            }

            setLoading(true)

            http.get('/api/locations?term=' + val)
                .then(a => a.data)
                .then(data => {
                    if (data && data.items)
                        setOptions(data.items);
                    else
                        setOptions([]);
                })
                .catch(err => {
                    console.log(err);
                    setOptions([])
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 300);
        setTimeoutHandle(handle);
    }

    const handleChange = (event, val) => {
        setValue(val);
    };

    return (
        <Autocomplete
            sx={{ width: '100%' }}
            multiple
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.displayValue === value.displayValue}
            filterOptions={(options) => options}
            getOptionLabel={(option) => option ? option.displayValue : ''}
            options={options}
            loading={loading}
            value={getValue()}
            onChange={handleChange}
            onInputChange={onInputChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='standard'
                    label="Destination"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}

export default Destination
// export default memo(Destination, (prevProps, nextProps) => {
//     return _.isEqual(prevProps.getValue(), nextProps.getValue())
// })