import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import React from 'react'

export default function Timezone(props) {
    const { getValue, setValue, disabled } = props;
    const value = getValue() || 'US/Central';

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FormControl disabled={disabled} variant="standard" sx={{ width: '100%', minWidth: 175 }}>
            <InputLabel>TimeZone</InputLabel>
            <Select
                value={value}
                onChange={handleChange}
            >
                <MenuItem value="US/Eastern">Eastern Time (EST)</MenuItem>
                <MenuItem value="US/Central">Central Time (CST)</MenuItem>
                <MenuItem value="US/Mountain">Mountain Time (MST)</MenuItem>
                <MenuItem value="US/Pacific">Pacific Time (PST)</MenuItem>
            </Select>
        </FormControl>
    )
}