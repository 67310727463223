import React from "react";
import OrderList from "./order-list";
import { OrdersProvider } from './context'

export default function Orders(props) {
  return (
    <OrdersProvider>
      <OrderList />
    </OrdersProvider>
  );
}
