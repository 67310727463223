import React from 'react';
import http from '../../http';

export const OrdersStateContext = React.createContext();
export const OrdersDispatchContext = React.createContext();

export function OrdersProvider({ children }) {
    var [state, dispatch] = React.useReducer(reducer, initState());
    return (
        <OrdersStateContext.Provider value={state}>
            <OrdersDispatchContext.Provider value={dispatch}>
                {children}
            </OrdersDispatchContext.Provider>
        </OrdersStateContext.Provider>
    );
}

export function useOrdersState() {
    var context = React.useContext(OrdersStateContext);
    if (context === undefined) {
        throw new Error("useOrdersState must be used within a OrdersProvider");
    }
    return context;
}

export function useOrdersDispatch() {
    var context = React.useContext(OrdersDispatchContext);
    if (context === undefined) {
        throw new Error("useOrdersDispatch must be used within a OrdersProvider");
    }
    return context;
}

export const ACTIONS = {
    ORDERS_LOADING: 'ORDERS_LOADING',
    ORDERS_LOADED: 'ORDERS_LOADED',
    ORDERS_LOAD_FAILED: 'ORDERS_LOAD_FAILED',
    ORDERS_FILTER_CHANGED: "ORDERS_FILTER_CHANGED",

    ADD_ORDER: 'ADD_ORDER',
    UPDATE_ORDER: 'UPDATE_ORDER',
    ORDER_ADDED: 'ORDER_ADDED',
    ORDER_UPDATED: 'ORDER_UPDATED',
    CLOSE_ORDER_FORM: 'CLOSE_ORDER_FORM',

    ORDER_STATUS_CHANGING: 'ORDER_STATUS_CHANGING',
    ORDER_STATUS_CHANGED: 'ORDER_STATUS_CHANGED',
    ORDER_STATUS_CHANGE_FAILED: 'ORDER_STATUS_CHANGE_FAILED',

    DRIVERS_LOADED: 'DRIVERS_LOADED',

    ALERT: 'ALERT',
    CLOSE_ALERT: 'CLOSE_ALERT',
}

export function initState() {
    return {
        filter: {
            status: 'ACTIVE',
        },
        isLoading: false,
        alert: null, // {type: 'warning', message: ''}
        orders: {
            items: [],
            size: 10,
            page: 1,
            total: 0
        },
        orderForm: {
            isOpen: false
        },
        drivers: []
    }
}

export function reducer(state, action) {
    return actions[action.type](state, action.payload);
}

const actions = {};

actions[ACTIONS.ORDERS_LOADING] = (state, payload) => {
    return {
        ...state,
        isLoading: true,
        alert: null
    }
}

actions[ACTIONS.ORDERS_LOADED] = (state, payload) => {
    return {
        ...state,
        orders: payload,
        isLoading: false,
        alert: null
    }
}

actions[ACTIONS.ORDERS_LOAD_FAILED] = (state, payload) => {
    return {
        ...state,
        isLoading: false,
        alert: payload
    }
}

actions[ACTIONS.ORDERS_FILTER_CHANGED] = (state, payload) => {
    return {
        ...state,
        filter: payload.filter
    }
}

actions[ACTIONS.ADD_ORDER] = (state, payload) => {
    return {
        ...state,
        alert: null,
        orderForm: {
            order: payload ? { status: 'ACTIVE', fulfillmentType: payload.fulfillmentType, query: { ...payload.query } } : null,
            isOpen: true
        }
    }
}

actions[ACTIONS.ORDER_ADDED] = (state, payload) => {
    return {
        ...state,
        orderForm: {
            isOpen: false
        }
    }
}

actions[ACTIONS.UPDATE_ORDER] = (state, payload) => {
    return {
        ...state,
        alert: null,
        orderForm: {
            order: payload,
            isOpen: true
        }
    }
}

actions[ACTIONS.ORDER_UPDATED] = (state, payload) => {
    return {
        ...state,
        alert: null,
        orderForm: {
            order: null,
            isOpen: false
        }
    }
}

actions[ACTIONS.CLOSE_ORDER_FORM] = (state, payload) => ({
    ...state,
    orderForm: {
        isOpen: false
    }
})

actions[ACTIONS.ORDER_STATUS_CHANGING] = (state, payload) => ({
    ...state,
    orders: {
        ...state.orders,
        items: state.orders.items.map(a => a.id === payload.order.id ? { ...a, isLoading: true } : a)
    }
});

actions[ACTIONS.ORDER_STATUS_CHANGED] = (state, payload) => ({
    ...state,
    orders: {
        ...state.orders,
        items: state.orders.items.map(a => a.id === payload.order.id ?
            {
                ...a,
                isLoading: false,
                status: payload.status
            } : a)
    }
});

actions[ACTIONS.ORDER_STATUS_CHANGE_FAILED] = (state, payload) => ({
    ...state,
    alert: {
        type: "warning",
        message: "Couldn't change the order status to " + payload.status + ". " + payload.error.message
    },
    orders: {
        ...state.orders,
        items: state.orders.items.map(a => a.id === payload.order.id ?
            {
                ...a,
                isLoading: false
            } : a)
    }
});

actions[ACTIONS.DRIVERS_LOADED] = (state, payload) => ({
    ...state,
    drivers: payload.items.map(a => ({ id: a.driverId, text: `${a.firstName} ${a.lastName}` }))
});

actions[ACTIONS.ALERT] = (state, payload) => ({
    ...state,
    alert: payload
});

actions[ACTIONS.CLOSE_ALERT] = (state, payload) => ({
    ...state,
    alert: null
});


export function loadOrders(state, dispatch, filter = null, paging = null) {
    dispatch({ type: ACTIONS.ORDERS_LOADING });
    if (!paging) paging = state.orders;
    const pagingQuery = `page=${paging.page}&size=${paging.size}`;
    const query = filter ? Object.keys(filter)
        .filter(key => !!filter[key])
        .map(key => key + '=' + filter[key].toString())
        .join('&') : '';
    return http.get('/api/orders?' + [query, pagingQuery].join('&'))
        .then(res => res.data)
        .then(data => {
            dispatch({ type: ACTIONS.ORDERS_LOADED, payload: data });
        })
        .catch(err => {
            console.warn(err)
            dispatch({
                type: ACTIONS.ORDERS_LOAD_FAILED, payload: {
                    type: 'warning',
                    message: 'Error on loading the data'
                }
            });
        });
}

export function filterOrders(state, dispatch, filter) {
    dispatch({ type: ACTIONS.ORDERS_FILTER_CHANGED, payload: { filter } })
    return loadOrders(state, dispatch, filter, { page: 1, size: state.orders.size });
}

export function onOrdersPageChange(state, dispatch, paging) {
    return loadOrders(state, dispatch, state.filter, paging);
}

export async function createOrder(newOrder, clearForm, setLoading, setError, state, dispatch) {
    setLoading(true)
    return http.post("/api/orders", newOrder)
        .then(res => {
            clearForm();
            dispatch({ type: ACTIONS.ORDER_ADDED })
            return loadOrders(state, dispatch, state.filter, { page: 1, size: state.orders.size });
        })
        .catch(err => {
            let message = 'Something went wrong!'
            if (err.response) {
                message = err.response.data?.message ? err.response.data.message : err.response.statusText;
            }
            setError(new Error(message));
            setLoading(false);
        })
}

export async function updateOrder(order, clearForm, setLoading, setError, state, dispatch) {
    setLoading(true)
    return http.put("/api/orders/" + order.id, order)
        .then(async res => {
            clearForm();
            dispatch({ type: ACTIONS.ORDER_UPDATED })
            return loadOrders(state, dispatch, state.filter);
        })
        .catch(err => {
            let message = 'Something went wrong!'
            if (err.response) {
                message = err.response.data?.message ? err.response.data.message : err.response.statusText;
            }
            setError(new Error(message));
            setLoading(false);
        })
}

export function changeOrderStatus(order, status, state, dispatch) {
    dispatch({ type: ACTIONS.ORDER_STATUS_CHANGING, payload: { order } });
    return http.put(`/api/orders/${order.id}/status`, { value: status })
        .then(res => {
            return dispatch({ type: ACTIONS.ORDER_STATUS_CHANGED, payload: { order, status } });
        })
        .catch(err => {
            let message = 'Something went wrong!'
            if (err.response) {
                message = err.response.data?.message ? err.response.data.message : err.response.statusText;
            }
            dispatch({ type: ACTIONS.ORDER_STATUS_CHANGE_FAILED, payload: { order, status, error: new Error(message) } });
        });
}

export function fetchDrivers(state, dispatch) {
    return http.get('/api/drivers?status=Active')
        .then(res => res.data)
        .then(data => dispatch({ type: ACTIONS.DRIVERS_LOADED, payload: data }))
        .catch(err => {
            let message = 'Something went wrong!'
            if (err.response) {
                message = err.response.data?.message ? err.response.data.message : err.response.statusText;
            }
            dispatch({ type: ACTIONS.ALERT, payload: { type: 'warning', message } })
        });
}