import axios from 'axios';
import Cookies from 'js-cookie';

const http = axios.create({
    headers: {
        'content-type': 'application/json'
    }
});

http.interceptors.response.use(undefined, err => {
    if (err && err.response && [401, 403].includes(err.response.status)) {
        Cookies.remove("Authorization");
        window.location.reload();
    }
    return Promise.reject(err);
})

export default http;