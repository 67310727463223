import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Avatar, Box, Collapse, IconButton, Stack, Chip } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { blueGrey } from '@material-ui/core/colors';
import { Duration } from 'luxon'
/**
 * 
 * @param {
    "label": "LAS6",
    "line1": "4550 Nexus Way",
    "line2": null,
    "line3": null,
    "city": "LAS VEGAS",
    "state": "NV",
    "country": "US",
    "postalCode": "89115",
    "latitude": 36.266778,
    "longitude": -115.072783,
    "timeZone": "America/Los_Angeles",
 * } location 
 */
function displayAddress(address, full = false) {
    if (!full)
        return `${address.city}, ${address.state} ${address.postalCode}`;
    return `${address.line1}<br/>${address.city}, ${address.state} ${address.postalCode}`;
}

function displayTime(time, newline = false) {
    const date = new Date(time);
    if (newline) {
        return `${date.toLocaleDateString()}<br/>${date.toLocaleTimeString()}`
    }
    return date.toLocaleString();
}
function displayDuration(duration) {
    const value = Duration.fromMillis(duration);
    if (duration >= 86400000)
        return value.toFormat("d'd' h'h'");
    return value.toFormat("h'h' m'm'");
}
function calcPricePerMile(wo) {
    const price = (wo.payout?.value || 1) / (wo.totalDistance?.value || 1);
    return price.toFixed(2);
}

function calcLoadType(wo) {
    const stops = wo.loads?.flatMap(a => a.stops);
    return calcLoadTypeOfStops(stops)
}

function calcLoadTypeOfStops(stops) {
    if (stops && stops.length > 0) {
        const firstStop = stops[0];
        const lastStop = stops[stops.length - 1];
        if (firstStop.loadingType === "LIVE") {
            if (lastStop.unloadingType === "LIVE")
                return "Live";
            else
                return "Live/Drop";
        }
        return "Drop"
    }
    return null
}

const equipmentTypes = {
    FIFTY_THREE_FOOT_TRUCK: "53' Trailer",
    TWENTY_SIX_FOOT_BOX_TRUCK: "26' Truck",
    TWO_PUP_TRAILERS: "28' Trailer",
    FIFTY_THREE_FOOT_REEFER_TRUCK: "53' Reefer",
    TWENTY_SIX_FOOT_REEFER_TRUCK: "26' Reefer"
}

const trailerStatusTypes = {
    "PROVIDED": "Provided",
    "REQUIRED": "Required"
}

function StopList(props) {
    const { stops, equipmentType } = props;
    return (
        <TableContainer sx={{ mt: 1 }}>
            <Table aria-label="collapsible table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} padding="none">Stop</TableCell>
                        <TableCell>Equipment</TableCell>
                        <TableCell>Arrival</TableCell>
                        <TableCell>Departure</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stops.map((stop, index) => (
                        <TableRow key={index}>
                            <TableCell padding="none">
                                <StopNumber value={stop.stopSequenceNumber} />
                            </TableCell>
                            <TableCell>
                                {stop.location.label}
                                <div dangerouslySetInnerHTML={{ __html: displayAddress(stop.location, true) }}></div>
                            </TableCell>
                            <TableCell>
                                {equipmentTypes[equipmentType]}<br />{stop.loadingType || stop.unloadingType}
                            </TableCell>
                            <TableCell>
                                {stop.actions.length > 0 ? displayTime(stop.actions[0].plannedTime) : ''}
                            </TableCell>
                            <TableCell>
                                {stop.actions.length > 1 ? displayTime(stop.actions[1].plannedTime) : ''}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function LoadList(props) {
    const { loads } = props;
    return (
        <TableContainer sx={{ mt: 1 }}>
            <Table aria-label="collapsible table" size="small">
                <TableBody>
                    {loads.map((row, index) => (
                        <LoadListItem key={index} load={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function LoadListItem(props) {
    const { load } = props;
    const stops = load.stops || []
    const first = stops.length > 0 ? stops[0] : {}
    const last = stops.length > 0 ? stops[stops.length - 1] : {}
    const [open, setOpen] = useState(false);

    function onRowClick(e) {
        if (!['button', 'a', 'svg', 'path'].includes(e.target.tagName.toLowerCase()))
            setOpen(!open)
    }
    return (
        <>
            <TableRow onClick={onRowClick} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ pr: 0, width: 24 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Stack justifyContent="flex-start" direction="row" spacing={1}>
                        <StopNumber value={first.stopSequenceNumber} />
                        <span>{first.location?.label}</span>
                        <ArrowForwardIcon />
                        <StopNumber value={last.stopSequenceNumber} />
                        <span>{last.location?.label}</span>
                    </Stack>
                </TableCell>
                <TableCell>
                    {(load.distance?.value || 0).toFixed(1)} mi<br />
                    {/* {displayDuration(wo.totalDuration)} */}
                </TableCell>
                <TableCell padding="none">
                    {calcLoadTypeOfStops(stops)}
                </TableCell>
                <TableCell>
                    ${(load.payout?.value || 0).toFixed(2)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={1} />
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ mt: 2, mb: 3 }}>
                            <StopList stops={load.stops} equipmentType={load.equipmentType} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

function StopNumber(props) {
    const { value, sx } = props;
    return (
        <Avatar sx={{ width: 24, height: 24, bgcolor: blueGrey[500], fontSize: "0.75rem", ...sx }}>{value}</Avatar>
    )
}

function getStatusBGColor(item) {
    return item.status?.isSuccess ? '#b1ffc8' : '#ffe9e9';
}

function MatchedWO(props) {
    const [open, setOpen] = useState(false);
    const { item } = props;
    const { wo } = item;
    const { loads, startLocation, endLocation } = wo;
    const [firstLoad] = loads

    function onRowClick(e) {
        if (!['button', 'a', 'svg', 'path'].includes(e.target.tagName.toLowerCase()))
            setOpen(!open)
    }
    return (
        <>
            <TableRow onClick={onRowClick} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ pr: 0 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {item.status?.isSuccess ? <Chip color="success" label={item.loadId || "Notified"} size="small" /> : <Chip color="warning" label="Failed" size="small" />} <br />
                    <Typography variant="caption">
                        <div dangerouslySetInnerHTML={{ __html: displayTime(item.time * 1000, true) }}></div>
                    </Typography>
                </TableCell>
                <TableCell sx={{ pr: 0 }}>
                    <StopNumber value={1} />
                </TableCell>
                <TableCell>
                    <Typography variant="body2">
                        {startLocation.label}<br />{displayAddress(startLocation)}
                    </Typography>
                    <Typography variant="caption">
                        {displayTime(wo.firstPickupTime)}
                    </Typography>
                </TableCell>
                <TableCell padding="none">
                    <ArrowForwardIcon />
                </TableCell>
                <TableCell sx={{ pr: 0 }}>
                    <StopNumber value={wo.stopCount} />
                </TableCell>
                <TableCell>
                    <Typography variant="body2">
                        {endLocation.label}<br />{displayAddress(endLocation)}
                    </Typography>
                    <Typography variant="caption">
                        {displayTime(wo.lastDeliveryTime)}
                    </Typography>
                </TableCell>
                <TableCell>
                    {(wo.totalDistance?.value || 0).toFixed(1)} mi<br />
                    {displayDuration(wo.totalDuration)}
                </TableCell>
                <TableCell padding="none">
                    {wo.transitOperatorType === 'SINGLE_DRIVER' ? <PersonIcon /> : <GroupIcon />}
                </TableCell>
                <TableCell>
                    <Typography variant="body2">
                        {equipmentTypes[firstLoad?.equipmentType]}
                    </Typography>
                    <Typography variant="caption">
                        {firstLoad?.loadType === 'LOADED' ? "Provided" : "Required"}
                    </Typography>
                </TableCell>
                <TableCell padding="none">
                    {calcLoadType(wo)}
                </TableCell>
                <TableCell>
                    <Typography variant="body2">
                        ${(wo.payout?.value || 0).toFixed(2)}
                    </Typography>
                    <Typography variant="caption">
                        ${calcPricePerMile(wo)}/mi
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={1} />
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ mt: 2, mb: 3 }}>
                            <LoadList loads={wo.loads} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default function MatchedWOS(props) {

    const { matches } = props;

    return (
        <> {matches?.length > 0 &&
            <TableContainer sx={{ mt: 2 }}>
                <Table aria-label="collapsible table" size="small">
                    {/* <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Stops</TableCell>
                        <TableCell>Distance / Duration</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell align="right">Min Payout<br />Price</TableCell>
                        <TableCell>Equipment</TableCell>
                    </TableRow>
                </TableHead> */}
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan="12">
                                <Typography variant="h6">
                                    Matched orders
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {matches.map((row, index) => (
                            <MatchedWO key={index} item={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        }
        </>
    )
}