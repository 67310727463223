import React, { useRef, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Copyright from '../../components/Copyright'
import Alert from '@material-ui/core/Alert'

// logo
import logo from "../../images/logo.svg";

// context
import { useUserDispatch } from "../../context/UserContext";
function Login(props) {
  // global
  const userDispatch = useUserDispatch();
  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const unmounted = useRef(false);
  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, []);

  function loginUser() {
    setError(false);
    setIsLoading(true);

    if (!!loginValue && !!passwordValue) {
      fetch("/user/login", {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ email: loginValue, password: passwordValue })
      }).then(res => {
        if (!unmounted.current) {
          if (res.ok) {
            setError(false);
            userDispatch({ type: 'LOGIN_SUCCESS' })
            props.history.push('/app/dashboard')
          } else {
            //dispatch({ type: "LOGIN_FAILURE" });
            setError(true);
          }
        }
      }).catch(err => {
        //dispatch({ type: "LOGIN_FAILURE" });
        if (!unmounted.current) {
          setError(true);
        }
      }).finally(() => {
        if (!unmounted.current) {
          setIsLoading(false);
        }
      })
    } else {
      //dispatch({ type: "LOGIN_FAILURE" });
      setError(true);
      setIsLoading(false);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          sx={{ width: '100%' }}
          src={logo}
        />
        <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
          Sign in
        </Typography>
        <form noValidate
          onSubmit={(e) => {
            e.preventDefault()
            loginUser()
          }}>
          {error && <Alert severity="error" sx={{ mb: 2, mr: 1 }}>Invalid email or password.</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={loginValue}
            onChange={e => setLoginValue(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={passwordValue}
            onChange={e => setPasswordValue(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          {isLoading && (
            <LinearProgress />
          )}
          <Button type="submit"
            disabled={
              loginValue.length === 0 || passwordValue.length === 0 || isLoading
            }
            fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Login
          </Button>
        </form>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container >
  );
}

export default withRouter(Login);
