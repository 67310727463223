import React from 'react'
import { Box, Slider, InputLabel } from '@material-ui/core';


export default function StopRange(props) {
    const min = 1, max = 11;
    const { getValue, setValue } = props;
    const value = getValue();
    if (!value[0]) value[0] = 1;
    if (!value[1]) value[1] = max;

    const valueLabelFormat = value => {
        if (value === max) return "Any";
        return value?.toString();
    }

    const displayValue = value => {
        if (value[0] === value[1])
            return valueLabelFormat(value[0])
        return valueLabelFormat(value[0]) + ' - ' + valueLabelFormat(value[1])
    }

    const handleChange = (event, newValue) => {
        let [minVal, maxVal] = newValue;
        if(value[0] === minVal && value[1] === maxVal) return;
        if (maxVal === max) maxVal = null
        setValue([minVal, maxVal]);
    };

    return (
        <Box sx={{ mt: 2, ml: 1 }}>
            <InputLabel variant="standard">
                Stops: {displayValue(value)}
            </InputLabel>
            <Slider
                getAriaLabel={() => 'Stops'}
                value={value}
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valueLabelFormat}
                onChange={handleChange}
                min={min}
                max={max}
                valueLabelDisplay="auto"
            />
        </Box>
    )
}