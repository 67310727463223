import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core'
import React from 'react'

const items = [
    { key: 25, value: "25" },
    { key: 30, value: "30" },
    { key: 35, value: "35" },
    { key: 40, value: "40" },
    { key: 45, value: "45" },
    { key: 50, value: "50" },
    { key: 75, value: "75" },
    { key: 100, value: "100" },
    { key: 150, value: "150" },
    { key: 200, value: "200" },
]

export default function OriginRadius(props) {
    const { getValue, setValue } = props;

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel id="radius-label">Radius</InputLabel>
            <Select
                value={getValue()}
                label="Radius"
                onChange={handleChange}
            >
                {items.map(a => (
                    <MenuItem key={a.key} value={a.key}>{a.value}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}