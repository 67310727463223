import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'
import React from 'react'
import _ from 'lodash'

export const items = [
    {
        id: "FIFTY_THREE_FOOT_TRUCK",
        text: "53' Trailer",
        includes: ["FIFTY_THREE_FOOT_TRUCK", "SKIRTED_FIFTY_THREE_FOOT_TRUCK", "FIFTY_THREE_FOOT_DRY_VAN", "FIFTY_THREE_FOOT_A5_AIR_TRAILER"]
    },
    {
        id: "TWENTY_SIX_FOOT_BOX_TRUCK",
        text: "26' Truck",
        includes: ["TWENTY_SIX_FOOT_BOX_TRUCK"]
    },
    {
        id: "TWO_PUP_TRAILERS",
        text: "28' Trailer",
        includes: ["TWO_PUP_TRAILERS", "TWO_SWAP_BODIES", "ONE_PUP_TRAILER"]
    },
    {
        id: "FIFTY_THREE_FOOT_REEFER_TRUCK",
        text: "53' Reefer",
        includes: ["FIFTY_THREE_FOOT_REEFER_TRUCK"]
    },
    {
        id: "TWENTY_SIX_FOOT_REEFER_TRUCK",
        text: "26' Reefer",
        includes: ["TWENTY_SIX_FOOT_REEFER_TRUCK"]
    },
    {
        id: "FIFTY_THREE_FOOT_CONTAINER",
        text: "53' Container",
        includes: ["FIFTY_THREE_FOOT_CONTAINER"]
    },
    {
        id: "TWENTY_FOOT_CONTAINER",
        text: "20' Container",
        includes: ["TWENTY_FOOT_CONTAINER"]
    },
    {
        id: "FORTY_FOOT_CONTAINER",
        text: "40' Container",
        includes: ["FORTY_FOOT_CONTAINER"]
    },
    {
        id: "FORTY_FIVE_FOOT_CONTAINER",
        text: "45' Container",
        includes: ["FORTY_FIVE_FOOT_CONTAINER"]
    },
    {
        id: "FORTY_FOOT_HIGHCUBE_CONTAINER",
        text: "40' HC Container",
        includes: ["FORTY_FOOT_HIGHCUBE_CONTAINER"]
    },
    {
        id: "FORTY_FIVE_FOOT_HIGHCUBE_CONTAINER",
        text: "45' HC Container",
        includes: ["FORTY_FIVE_FOOT_HIGHCUBE_CONTAINER"]
    },
]

function EquipmentType(props) {
    const { getValue, setValue } = props;
    const value = getValue();

    const handleChange = (event) => {
        const selectedItems = event.target.value;
        const allEquipmentTypes = Array.from(new Set(selectedItems.map(item => items.find(a => a.id === item)).flatMap(a => a.includes)));
        setValue(selectedItems, allEquipmentTypes);
    };

    const renderValue = selected => {
        return selected.map(a => items.find(b => b.id === a).text).join(', ');
    };

    return (
        <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel>Equipment</InputLabel>
            <Select
                multiple
                value={value}
                label="Equipment"
                onChange={handleChange}
                renderValue={renderValue}
            >
                {items.map(a => (
                    <MenuItem key={a.id} value={a.id}>{a.text}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default EquipmentType

// export default React.memo(EquipmentType, (prev, next) => {
//     return _.isEqual(prev.getValue(), next.getValue())
// })