import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import React from 'react'

const items = [
    { id: 'SINGLE_DRIVER', text: "Solo" },
    { id: 'TEAM_DRIVER', text: "Team" },
]

export default function TripType(props) {
    const { getValue, setValue } = props;
    const value = getValue();

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const renderValue = selected => {
        return selected.map(a => items.find(b => b.id === a).text).join(', ');
    };

    return (
        <FormControl variant="standard" sx={{ width: '100%', minWidth: 175 }}>
            <InputLabel>Trip type</InputLabel>
            <Select
                multiple
                value={value}
                onChange={handleChange}
                renderValue={renderValue}
            >
                {items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={value.indexOf(item.id) > -1} />
                        <ListItemText primary={item.text} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}