import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { Box, Container, Toolbar } from '@material-ui/core'

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Orders from "../../pages/orders";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {

  // global
  var layoutState = useLayoutState();

  return (
    <Box sx={{ display: 'flex' }}>
      <Header history={props.history} />
      <Sidebar />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="100%" sx={{ mt: 2, mb: 2 }}>
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/orders" component={Orders} />
          </Switch>
        </Container>
      </Box>
    </Box>
  );
}

export default withRouter(Layout);
