import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import React from 'react'

export default function OrderStatus(props) {
    const { getValue, setValue } = props;
    const value = getValue();

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FormControl variant="standard" sx={{ width: '100%', minWidth: 175 }}>
            <InputLabel>Status</InputLabel>
            <Select
                value={value}
                onChange={handleChange}
            >
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
            </Select>
        </FormControl>
    )
}