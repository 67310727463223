import React, { useState } from 'react'
import { DateTimePicker } from '@material-ui/lab'
import { TextField } from '@material-ui/core';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

function utcToTZ(utcDate, tz) {
    return utcDate ? utcToZonedTime(utcDate, tz) : null
}

function tzToUtc(tzDate, tz) {
    return tzDate ? zonedTimeToUtc(tzDate, tz) : null;
}

export default function DateTime(props) {
    const { getValue, setValue, label, timezone, disabled } = props;
    const value = utcToTZ(getValue() || null, timezone);

    const handleChange = (newValue) => {
        setValue(tzToUtc(newValue, timezone));
    };

    return (
        <DateTimePicker
            disabled={disabled}
            label={label}
            value={value}
            onChange={handleChange}
            renderInput={(params) =>
                <TextField sx={{ width: "100%" }} variant="standard" {...params} />}
        />
    )
}