import React from "react";
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";

export default function MainMenuItem({
    link,
    icon,
    label,
    location
}) {

    var isLinkActive =
        link &&
        (location.pathname === link || location.pathname.indexOf(link) !== -1);

    return (
        <ListItemButton
            component={link && Link}
            to={link}
            selected={isLinkActive}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={label} />
        </ListItemButton>
    );
}