import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import React, { useState } from 'react'

const items = [
    { id: 'LIVE', text: "Live" },
    { id: 'DROP', text: "Drop & Hook" },
]

export default function LoadType(props) {
    const { getValue, setValue } = props;
    const value = getValue();

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const renderValue = selected => {
        return selected.map(a => items.find(b => b.id === a).text).join(', ');
    };

    return (
        <FormControl variant="standard" sx={{ width: '100%', minWidth: 175 }}>
            <InputLabel>Load type</InputLabel>
            <Select
                multiple
                value={value}
                onChange={handleChange}
                renderValue={renderValue}
            >
                {items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={value.indexOf(item.id) > -1} />
                        <ListItemText primary={item.text} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}