import * as React from 'react';
import List from '@material-ui/core/List';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import MainMenuItem from './MainMenuItem';

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <DashboardIcon /> },
  { id: 1, label: "Orders", link: "/app/orders", icon: <ShoppingCartIcon /> }
];


export default function MainMenu({ location }) {
  return (
    <List>
      {structure.map(link => (
        <MainMenuItem
          key={link.id}
          location={location}
          {...link}
        />
      ))}
    </List>
  );
}