import React from 'react'
import { Box, Slider, InputLabel } from '@material-ui/core';

const min = 0, max = 28;

export const calculateValue = value => {
    if (value && value > 24) {
        return (value - 24) * 24 + 24
    }
    return value
}

export const calculatedValueBack = value => {
    if (value === null || value === undefined) return min;
    if (value > 24) {
        return Math.floor(value / 24) - 1 + 24
    }
    return value
}

export function valueLabelFormat(value) {
    const actualVal = calculateValue(value);

    // if (value === calculateValue(max) || value === calculateValue(min)) return "Any";
    if (actualVal === 0) return "0";
    if (actualVal === 1) return "1 hr";
    if (actualVal < 24) return actualVal.toString() + ' hrs';
    const days = Math.floor(actualVal / 24);
    if (days === 1) return "1 day";
    return days.toString() + ' days';
}

export function displayValue(value) {
    if (value[0] === value[1])
        return valueLabelFormat(value[0])
    return valueLabelFormat(value[0]) + ' - ' + valueLabelFormat(value[1])
}

export default function TimeWithin(props) {

    const { getValue, setValue, disabled } = props;
    let value = getValue();
    //if (!value[1]) value[1] = max;
    value[0] = calculatedValueBack(value[0]);
    value[1] = calculatedValueBack(value[1]);

    const handleChange = (event, newValue) => {
        let [minVal, maxVal] = newValue;
        if(value[0] === minVal && value[1] === maxVal) return;
        // if (maxVal === max) maxVal = null
        setValue([calculateValue(minVal), calculateValue(maxVal)]);
    };

    return (
        <Box sx={{ mt: 2, ml: 1 }}>
            <InputLabel disabled={disabled} variant="standard">
                Start within: {displayValue(value)}
            </InputLabel>
            <Slider
                disabled={disabled}
                value={value}
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valueLabelFormat}
                onChange={handleChange}
                min={min}
                max={max}
                // step={1}
                // scale={calculateValue}
                valueLabelDisplay="auto"
            />
        </Box>
    )
}