import React from 'react'
import { TextField, InputAdornment } from '@material-ui/core';


export default function Payout(props) {
    const { getValue, setValue, min, max } = props;
    const value = getValue() || '';

    const handleChange = (event) => {
        setValue(event.target.value === '' ? null : parseInt(event.target.value));
    };

    return (
        <TextField variant="standard"
            type="number"
            label="Min. Payout"
            value={value}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            inputProps={{ step: 50, min, max }}
            error={!value || value < min || value > max}
            onChange={handleChange} />
    )
}